import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="md:container md:mx-auto">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            પલિયડ ગામ દિવાળી ગરબા - મોટો ભાગ 2023
            <br />
          </h2>
        </div>
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            src="https://www.youtube.com/embed/C-30WPcNozA?si=VwGcDSvixk2g0lqr"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
